<template>
  <div class="main">
    <div class="h-separator">
      <span></span>
      <span class="title">CADASTRO DA FAIXA DO BPSI PACKAGE</span>
      <span></span>
    </div>
    <b-form @submit="onSubmit" ref="form">
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-product" class="title">Serviço *</label>
              <span></span>
            </div>
            <b-form-select
              class="col-12"
              :options="servicesOptions"
              v-model="selected"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Por favor, selecione um serviço</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <div class="h-separator">
              <label for="input-add-product" class="title">Unidade *</label>
              <span></span>
            </div>
            <b-form-select
              class="col-12"
              :options="units"
              v-model="unit"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >Peso Unidade</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <ServiceInput v-model="ranges" :fields="servicesFields"> </ServiceInput>
      <div class="d-flex flex-column align-items-end">
        <b-button class="btnsave" type="submit" variant="primary"
          >Salvar</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import api from "@/services";
import { toast } from "../../utils.js";
import redirectorservice from "../../store/modules/home/redirectorservice";
import ServiceInput from "@/components/Inputs/Service";

export default {
  components: { ServiceInput },
  modules: {
    redirectorservice,
  },
  data: () => ({
    servicesFields: [
      // values for Service Input
      { label: "Tipo", key: "type" },
      { label: "Mínimo", key: "min" },
      { label: "Máximo", key: "max" },
      { label: "Valor", key: "value" },
    ],
    services: [], // all services from api (original object)
    servicesOptions: [], // services formatted for select
    ranges: undefined, // service_ranges
    selected: null, // service select value
    units: [
      { value: "ounce", text: "Ounce" },
      { value: "gram", text: "Gram" },
      { value: "pound", text: "Pound" },
      { value: "kilogram", text: "Kilogram" },
    ],
    unit: null,
  }),
  props: ["user"],
  beforeMount() {
    //console.log(redirectorservice.state.form);
    api.get("service/index").then((response) => {
      this.services = response.data.services;
      this.servicesOptions = this.services.map((s) => ({
        value: s.id,
        text: ({
          "BPSI-01": "BPSI Prime (Postal)" ,
          "BPSI-08": "BPSI Packet Standard (Postal)",
          "BPSI-02": "BPSI Packet Express (Postal)",
          "BPSI-03": "BPSI Express Plus (Courier)",
          "BPSI-22": "BPSI Express Plus RX",
          "BPSI-11": "BPSI Tracked Parcel",
          "BPSI-25": "Packet Standard PRC",
          "BPSI-14": "Express Plus PRC"
        })[s.description] || "BPSI Unknown"
      }));
    });
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();

      let form = {
        user_id: this.user.id,
        service_id: this.selected,
        unit: this.unit,
        service_ranges: this.ranges,
      };
      // console.log(form);

      api.post("/service/createrange", form).then(
        (response) => {
          // console.log(response);
          if ([200, 201, 204].includes(response.status)) {
            // this.$store.dispatch;

            toast(
              this,
              "success",
              "Serviço Registrado",
              "Serviço registrado com sucesso"
            );

            this.$bvModal.hide("service-form");
            this.$emit("finish");
            //this.$router.replace({ path: "/redirectors/redirector" });
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Serviço não pode ser registrado",
            "Ocorreu um erro",
            error.response +
              (error.response.status === 422
                ? " => " + error.response.errors
                : "")
          );
          return Promise.reject(error);
        }
      );
      // */
    },
  },
};
</script>
<style lang="stylus">
@import '../../style/main/colors.styl';

.h-separator {
  display: flex;

  .title {
    padding: 0px 10px;
    font-size: 14px;
    color: light;
    font-weight: bold;
    border-radius: 1rem;
    background: primary;
  }

  span {
    align-self: center;
  }

  span:last-child {
    border: 1px dashed primary;
    height: 1px;
  }

  span:last-child {
    flex-grow: 1;
  }
}

.btnsave {
  width: 100px;
  background-color: #4472C4;
  font-size: 14px;
}
</style>

import config from "@/config";

export default {
  namespaced: true,
  state: {
    title: "Pacotes",
    itemsKey: "package",
    linkTo: "sendbox.items",
    createPacket: true,

    forms: {
      base: [
        {
          key: "parcel_number",
          label: "Número do Pacote",
          type: "text",
          col: "6",
        },
        {
          key: "external_customer_id",
          label: "Identificação do Cliente Externo",
          type: "text",
          col: "3",
        },
        {
          key: "service",
          col: "3",
          label: "Serviço",
          type: "select",
          options: config.dev
            ? [
                { value: "BPSI-01", text: "BPSI Prime (Postal)" },
                { value: "BPSI-08", text: "BPSI Packet Standard (Postal)" },
                { value: "BPSI-02", text: "BPSI Packet Express (Postal)" },
                { value: "BPSI-03", text: "BPSI Express Plus (Courier)" }, // Exclusive to prod, but keeping for test cbd
                { value: "BPSI-22", text: "BPSI Express Plus RX" },
                { value: "BPSI-11", text: "BPSI Tracked Parcel" },
                { value: "BPSI-25", text: "Express Plus PRC" },
                { value: "BPSI-14", text: "Packet Standard PRC"}
              ]
            : [
                { value: "BPSI-01", text: "BPSI Prime (Postal)" },
                { value: "BPSI-08", text: "BPSI Packet Standard (Postal)" },
                { value: "BPSI-02", text: "BPSI Packet Express (Postal)" },
                { value: "BPSI-03", text: "BPSI Express Plus (Courier)" },
                { value: "BPSI-22", text: "BPSI Express Plus RX" },
                { value: "BPSI-11", text: "BPSI Tracked Parcel" },
                { value: "BPSI-25", text: "Express Plus PRC" },
                { value: "BPSI-14", text: "Packet Standard PRC"}
              ],
        },
        {
          key: "tax_modality",
          col: "3",
          label: "Modalidade Tributária",
          type: "select",
          options: [
            { value: "ddu", text: "DDU" },
            { value: "ddp", text: "DDP" },
          ],
          options2: [{ value: "ddp", text: "DDP" }],
        },
        {
          key: "type of_package",
          col: "3",
          label: "Tipo de Embalagem",
          type: "select",
          options: [
            { value: "bag", text: "Envelope/Bag" },
            { value: "box", text: "Box" },
          ],
        },
      ],
    },
  },
};
